@keyframes 'blink' {
  from,
  to {
    color: transparent;
  }

  50% {
    color: #fff;
  }
}
