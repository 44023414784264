@import 'shariff/build/shariff.complete';

.shariff {

  ul {
    list-style-type: none;

    .shariff-button {
      cursor: pointer;
      display: inline-block;
      height: 70px;
      margin-right: 5px;
      width: 70px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .shariff-button {

    .share_text,
    .fa {
      display: none;
    }

    a {
      display: block;
      height: 70px;
      width: 70px;
    }

    &.facebook a {
      background: #3a5a99 url('../Images/facebook.png') center center no-repeat;
    }

    &.twitter a {
      background: #55acee url('../Images/twitter.png') center center no-repeat;
    }

    &.googleplus a {
      background: #dc4b3e url('../Images/googleplus.png') center center no-repeat;
    }

    &.whatsapp a {
      background: #25d366 url('../Images/whattsapp.png') center center no-repeat;
    }

    &.whatsapp {
      display: none;

      @include breakpoint(small only) {
        display: inline-block;
      }
    }

    &.facebook a:hover,
    &.twitter a:hover,
    &.googleplus a:hover,
    &.whatsapp a:hover {
      background-size: 95%;
    }
  }
}
