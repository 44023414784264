header {
  background: #fff;
  padding-top: 104px;
  position: relative;

  &.content {
    background: url('../Images/section-shadow.png') right bottom no-repeat;
  }
}

@include breakpoint(small only) {
  header {
    padding-top: 0;
  }
}



.logo {
  left: 50%;
  margin-left: -52px;
  position: absolute;
  top: 30px;
  z-index: 1;
}

@include breakpoint(small only) {
  .logo {
    margin: 42px 0 0;
    position: static;
    text-align: center;
  }
}



.header-callout {
  background-color: $color-primary;
  bottom: 0;
  margin: -16% 0 0 34.01%;
  min-height: 310px -67;
  padding: 20px 15px;
  position: relative;
  width: 66%;
  z-index: 1;

  @include breakpoint(small only) {
    margin-bottom: 35px;
  }

  &::before {
    background: url('../Images/callout-shadow-left.png') 0 100% no-repeat;
    content: '';
    height: 100%;
    left: -74px;
    position: absolute;
    top: 0;
    width: 143px;
  }

  &::after {
    background: url('../Images/callout-effect.svg') 0 0 no-repeat;
    bottom: -71px;
    content: '';
    height: 71px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.content {
  .header-callout {
    margin-top: -9%;
    min-height: 160px;

    &--nopicture {
      margin-top: 0;
    }

    @include breakpoint(small only) {
      margin-top: 0;
      min-height: 0;

      .callout-intro {
        padding-bottom: 0;
      }
    }
  }
}



@include breakpoint(small only) {
  .header-callout {
    margin-left: 10%;
    width: 90%;

    p {
      font-size: 2rem;
    }
  }
}

@include breakpoint(medium only) {
  .header-callout {
    margin-left: 20%;
    margin-top: -70px;
    width: 80%;
  }
}






.stage {
  background: url('../Images/section-shadow.png') right bottom no-repeat;
  font-size: 1.125rem;
  padding-bottom: 60px;
  position: relative;
}

@include breakpoint(small only) {
  .stage {
    background-image: none;
    padding-bottom: 0;
  }
}



.page-abstract {
  padding-bottom: 10px;
  padding-top: 30px;

  h3 {
    color: $color-primary;
    font-family: $font-serif-regular;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.9375rem;
  }
}

@include breakpoint(small only) {
  .page-abstract h3 {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}

.cta-buttons {
  margin-top: 40px;
}

.callout-intro {
  font-family: $font-serif-regular;
  padding-bottom: 15px;
  position: relative;

  h1 {
    color: #fff;
    font-size: 2.625rem;
    font-weight: normal;
    line-height: 3.125rem;
    margin: 0;
    max-width: 80%;
  }

  .original-text,
  .new-text {
    font-size: 2.625rem;
    line-height: 3.125rem;
    margin: 0;
    max-width: 80%;
    min-height: 95px;
  }

  .original-text {
    visibility: hidden;
  }

  .new-text {
    background-color: $color-primary;
    color: #fff;

    &::after {
      animation: 1s blink step-end infinite;
      content: '|';
      position: relative;
      top: 1px;
    }
  }

  .new-text,
  .explanation {
    left: 0;
    position: absolute;
    top: 0;
  }

  .explanation {
    color: #f7c999;
    display: none;
    font-size: 1.5rem;
    line-height: 1.9375rem;
    margin: 0;
    max-width: 80%;
  }
}

@include breakpoint(small only) {
  .callout-intro {
    .explanation {
      font-size: 1.25rem;
      line-height: normal;
      max-width: 100%;
    }

    h1,
    .original-text,
    .new-text {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
}

@include breakpoint(medium only) {
  .callout-intro {
    .explanation {
      max-width: 100%;
    }
  }
}



// TEMP!
.quicksearch {
  color: #fff;
  font-size: 0;

  &-title {
    display: block;
    font-family: $font-serif-regular;
    font-size: 1.5rem;
    margin-bottom: 5px;
  }

  &-advanced {
    display: block;
    font-size: 1.125rem;
    padding-left: 27px;
    position: relative;

    &::before {
      background-color: #fff;
      color: $color-primary;
      content: '+';
      display: block;
      font-family: $font-sans-serif-bold;
      font-size: 20px;
      height: 20px;
      left: 0;
      line-height: 14px;
      position: absolute;
      text-align: center;
      top: 4px;
      transition: all 200ms linear;
      width: 20px;
    }

    &:link,
    &:visited {
      color: #fff;
    }
  }

  input,
  select {
    background-color: #fff;
    border: 1px solid #d77204;
    box-shadow: inset 2px 2px 8px 0 rgba(#000, .4);
    color: $color-primary;
    display: inline-block;
    font-family: $font-sans-serif;
    font-size: 1.125rem;
    height: 42px;
    padding: 10px 30px 8px 10px;
    vertical-align: top;

    &:focus {
      outline: none;
    }
  }

  .input-place,
  .select-distance {
    width: 35%;
  }

  .input-place {
    margin-right: .9375rem;
  }

  .select-distance {
    margin-right: .625rem;
  }

  &-submit {
    display: inline-block;
    height: 42px;
    position: relative;
    transition: all 200ms linear;
    vertical-align: top;
    width: 42px;

    svg {
      left: 5px;
      position: absolute;
      top: 5px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}


@include breakpoint(small only) {
  .quicksearch {
    .input-place,
    .select-distance {
      width: 100%;
    }
  }
}
