// DIESE DATEI WIRD AUTOMATISCH ERSTELLT!
// Based on https://www.liquidlight.co.uk/blog/article/creating-svg-sprites-using-gulp-and-sass/

$icons: (
  sprite: (width: 252px, height: 216px, svgPath: '../Images/sprite.view.svg'),
  agenda: (width: 36px, height: 36px, backgroundX: 16.666666666666668%, backgroundY: 0%),
  arrow-left: (width: 36px, height: 36px, backgroundX: 66.66666666666667%, backgroundY: 40%),
  arrow-right: (width: 36px, height: 36px, backgroundX: 0%, backgroundY: 20%),
  arrow-right-fancy: (width: 36px, height: 36px, backgroundX: 16.666666666666668%, backgroundY: 20%),
  book: (width: 36px, height: 36px, backgroundX: 33.333333333333336%, backgroundY: 0%),
  book-open: (width: 36px, height: 36px, backgroundX: 33.333333333333336%, backgroundY: 20%),
  calendar: (width: 36px, height: 36px, backgroundX: 0%, backgroundY: 40%),
  calendar-dark: (width: 36px, height: 36px, backgroundX: 16.666666666666668%, backgroundY: 40%),
  candles: (width: 36px, height: 36px, backgroundX: 33.333333333333336%, backgroundY: 40%),
  checkmark: (width: 36px, height: 36px, backgroundX: 50%, backgroundY: 0%),
  checkmark-round: (width: 36px, height: 36px, backgroundX: 50%, backgroundY: 20%),
  clock: (width: 36px, height: 36px, backgroundX: 50%, backgroundY: 40%),
  clock-simple: (width: 36px, height: 36px, backgroundX: 0%, backgroundY: 60%),
  compass: (width: 36px, height: 36px, backgroundX: 16.666666666666668%, backgroundY: 60%),
  cross: (width: 36px, height: 36px, backgroundX: 33.333333333333336%, backgroundY: 60%),
  cube: (width: 36px, height: 36px, backgroundX: 50%, backgroundY: 60%),
  document: (width: 36px, height: 36px, backgroundX: 66.66666666666667%, backgroundY: 0%),
  documents: (width: 36px, height: 36px, backgroundX: 66.66666666666667%, backgroundY: 20%),
  download: (width: 36px, height: 36px, backgroundX: 0%, backgroundY: 0%),
  drop: (width: 36px, height: 36px, backgroundX: 66.66666666666667%, backgroundY: 60%),
  eye: (width: 36px, height: 36px, backgroundX: 0%, backgroundY: 80%),
  fish: (width: 36px, height: 36px, backgroundX: 16.666666666666668%, backgroundY: 80%),
  flower: (width: 36px, height: 36px, backgroundX: 33.333333333333336%, backgroundY: 80%),
  info: (width: 36px, height: 36px, backgroundX: 50%, backgroundY: 80%),
  key: (width: 36px, height: 36px, backgroundX: 66.66666666666667%, backgroundY: 80%),
  letter: (width: 36px, height: 36px, backgroundX: 83.33333333333333%, backgroundY: 0%),
  lock: (width: 36px, height: 36px, backgroundX: 83.33333333333333%, backgroundY: 20%),
  lock-round: (width: 36px, height: 36px, backgroundX: 83.33333333333333%, backgroundY: 40%),
  magnifier: (width: 36px, height: 36px, backgroundX: 83.33333333333333%, backgroundY: 60%),
  pdf: (width: 36px, height: 36px, backgroundX: 83.33333333333333%, backgroundY: 80%),
  pencil: (width: 36px, height: 36px, backgroundX: 0%, backgroundY: 100%),
  person: (width: 36px, height: 36px, backgroundX: 16.666666666666668%, backgroundY: 100%),
  persons: (width: 36px, height: 36px, backgroundX: 33.333333333333336%, backgroundY: 100%),
  phone: (width: 36px, height: 36px, backgroundX: 50%, backgroundY: 100%),
  piggybank: (width: 36px, height: 36px, backgroundX: 66.66666666666667%, backgroundY: 100%),
  plus: (width: 36px, height: 36px, backgroundX: 83.33333333333333%, backgroundY: 100%),
  star: (width: 36px, height: 36px, backgroundX: 100%, backgroundY: 0%),
);

$sprite: map-get($icons, sprite) !default;

%sprite {
  background-image: url(map-get($sprite, svgPath));
  background-size: map-get($sprite, width) map-get($sprite, height);
  display: inline-block;
}

// Gets an attribute from the sass map
@function sprite-attr($icon, $attr) {
  $new-icon: map-get($icons, $icon);
  @if $new-icon == null {
    @warn "Can't find an icon with the name #{$icon}";
  }
  @return map-get($new-icon, $attr);
}

@function icon-attr($icon) {
  $attr: (
    height: sprite-attr($icon, height),
    width: sprite-attr($icon, width),
    x: sprite-attr($icon, backgroundX),
    y: sprite-attr($icon, backgroundY)
  );

  @return $attr;
}

@mixin sprite($icon, $type: all) {
  @if $type == all {
    // Shares the backgrounds
    @extend %sprite;
  }

  $icon-map: icon-attr($icon);

  // Outputs dimensions in em
  @if $type == all or $type == size {
    height: map-get($icon-map, height);
    width: map-get($icon-map, width);
  }

  // Outputs background position in em
  @if $type == all or $type == bg {
    background-position: map-get($icon-map, x) map-get($icon-map, y);
  }
}

.btn--agenda::before {
  @include sprite(agenda);
}

.btn--arrow-left::before {
  @include sprite(arrow-left);
}

.btn--arrow-right::before {
  @include sprite(arrow-right);
}

.btn--arrow-right-fancy::before {
  @include sprite(arrow-right-fancy);
}

.btn--book::before {
  @include sprite(book);
}

.btn--book-open::before {
  @include sprite(book-open);
}

.btn--calendar::before {
  @include sprite(calendar);
}

.btn--calendar-dark::before {
  @include sprite(calendar-dark);
}

.btn--candles::before {
  @include sprite(candles);
}

.btn--checkmark::before {
  @include sprite(checkmark);
}

.btn--checkmark-round::before {
  @include sprite(checkmark-round);
}

.btn--clock::before {
  @include sprite(clock);
}

.btn--clock-simple::before {
  @include sprite(clock-simple);
}

.btn--compass::before {
  @include sprite(compass);
}

.btn--cross::before {
  @include sprite(cross);
}

.btn--cube::before {
  @include sprite(cube);
}

.btn--document::before {
  @include sprite(document);
}

.btn--documents::before {
  @include sprite(documents);
}

.btn--download::before {
  @include sprite(download);
}

.btn--drop::before {
  @include sprite(drop);
}

.btn--eye::before {
  @include sprite(eye);
}

.btn--fish::before {
  @include sprite(fish);
}

.btn--flower::before {
  @include sprite(flower);
}

.btn--info::before {
  @include sprite(info);
}

.btn--key::before {
  @include sprite(key);
}

.btn--letter::before {
  @include sprite(letter);
}

.btn--lock::before {
  @include sprite(lock);
}

.btn--lock-round::before {
  @include sprite(lock-round);
}

.btn--magnifier::before {
  @include sprite(magnifier);
}

.btn--pdf::before {
  @include sprite(pdf);
}

.btn--pencil::before {
  @include sprite(pencil);
}

.btn--person::before {
  @include sprite(person);
}

.btn--persons::before {
  @include sprite(persons);
}

.btn--phone::before {
  @include sprite(phone);
}

.btn--piggybank::before {
  @include sprite(piggybank);
}

.btn--plus::before {
  @include sprite(plus);
}

.btn--star::before {
  @include sprite(star);
}
