@font-face {
  font-family: 'WeidemannStd-Bold';
  src: url('../Fonts/303D2E_0_0.eot');
  src: url('../Fonts/303D2E_0_0.eot?#iefix') format('embedded-opentype'),
  url('../Fonts/303D2E_0_0.woff2') format('woff2'),
  url('../Fonts/303D2E_0_0.woff') format('woff'),
  url('../Fonts/303D2E_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'WeidemannStd-BoldItalic';
  src: url('../Fonts/303D2E_1_0.eot');
  src: url('../Fonts/303D2E_1_0.eot?#iefix') format('embedded-opentype'),
  url('../Fonts/303D2E_1_0.woff2') format('woff2'),
  url('../Fonts/303D2E_1_0.woff') format('woff'),
  url('../Fonts/303D2E_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'WeidemannStd-Book';
  src: url('../Fonts/303D2E_2_0.eot');
  src: url('../Fonts/303D2E_2_0.eot?#iefix') format('embedded-opentype'),
  url('../Fonts/303D2E_2_0.woff2') format('woff2'),
  url('../Fonts/303D2E_2_0.woff') format('woff'),
  url('../Fonts/303D2E_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'WeidemannStd-BookItalic';
  src: url('../Fonts/303D2E_3_0.eot');
  src: url('../Fonts/303D2E_3_0.eot?#iefix') format('embedded-opentype'),
  url('../Fonts/303D2E_3_0.woff2') format('woff2'),
  url('../Fonts/303D2E_3_0.woff') format('woff'),
  url('../Fonts/303D2E_3_0.ttf') format('truetype');
}
