@import 'foundation-sites/scss/foundation';

$body-font-family: $font-sans-serif;
$global-flexbox: true;
$global-width: 64rem;
$grid-row-width: $global-width;


// Formulare
$form-label-font-size: rem-calc(18px);
$form-label-color: #828282;

$input-border-focus: 1px solid $color-primary;
$input-border: 1px solid #828282;
$input-color: $color-primary;
$input-font-size: rem-calc(18px);
$input-placeholder-color: #949494;
$input-shadow-focus: inset 2px 2px 8px 0 rgba(#000, .2);
$input-shadow: inset 2px 2px 8px 0 rgba(#000, .4);

// Tabellen
$table-head-font-color: $color-primary;
$table-border: 0;
$table-head-background: transparent;
$table-stripe: 0;
$table-padding: rem-calc(6 0);

@include foundation-global-styles;
@include foundation-flex-grid;
@include foundation-text-alignment;
@include foundation-forms;
@include foundation-table;
@include foundation-visibility-classes;
@include foundation-callout;

@include foundation-flex-classes;
