.accordion-item,
.accordion-item--course {
  margin-bottom: 15px;
  width: 100%;

  div {
    color: $color-secondary;
    display: none;
  }
}

.accordion-item {
  h3 {
    background-image: linear-gradient(170deg, #fff 20%, #e9e9e9 100%);
    cursor: pointer;
    font-family: $font-sans-serif-bold;
    font-size: 1rem;
    font-stretch: normal;
    margin: 0;
    margin-bottom: .5rem;
    padding: 6px 0 3px;
    position: relative;

    &::before {
      content: url('../Images/faq-arrow-down.png');
      height: 10px;
      position: absolute;
      right: 8px;
      top: 4px;
      width: 19px;
    }

    &.open::before {
      content: url('../Images/faq-arrow-top.png');
      height: 10px;
      position: absolute;
      right: 8px;
      top: 4px;
      width: 19px;
    }
  }
}


.accordion-item--course {
  h2 {
    background-image: linear-gradient(170deg, #fff 20%, #e9e9e9 100%);
    cursor: pointer;
    font-family: $font-sans-serif-bold;
    font-size: 1.125rem;
    font-stretch: normal;
    margin: 0;
    margin-bottom: 15px;
    padding: 10px 0;
    position: relative;

    &::before {
      content: url('../Images/faq-arrow-down.png');
      height: 10px;
      position: absolute;
      right: 8px;
      top: 9px;
      width: 19px;
    }

    &.open::before {
      content: url('../Images/faq-arrow-top.png');
      height: 10px;
      position: absolute;
      right: 8px;
      top: 8px;
      width: 19px;
    }
  }

  nav {
    margin-bottom: 10px;
  }

  h3 {
    font-size: 1rem;
    margin-top: 0;
  }

  div {
    border-bottom: 3px solid $color-secondary;
  }
}
