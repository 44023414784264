form {
  padding: 0;
  position: relative;

  ol {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      width: 100%;
    }
  }

  input,
  textarea {
    font-size: 1.125rem;
    height: 2.5rem;
    margin-bottom: .8235rem;
    outline: none;
    padding: 0 .5882rem;
  }

  textarea {
    min-height: 6rem;
    padding: .5882rem;
  }

  .csc-form-element-checkbox {
    label {
      line-height: 1.75rem;
    }
  }

  input[type='checkbox'] {
    display: inline-block;
    height: 1rem;
  }

  .csc-form-element-submit,
  .csc-form-element-reset,
  .csc-form-element-button {
    label {
      display: none;
    }

    &::before {
      clear: both;
      content: '.';
      display: block;
      height: 0;
      visibility: hidden;
    }
  }

  input[type='submit'],
  input[type='reset'],
  input[type='button'] {
    background-image: linear-gradient(170deg, #fff 20%, #e9e9e9 100%);
    border: 0;
    color: $color-secondary;
    display: inline-block;
    margin-bottom: 10px;
    min-height: 36px;
    padding: 6px 8px 3px;
    position: relative;
    text-decoration: none;
    transition: all 100ms linear;

    &:link,
    &:visited {
      color: $color-secondary;
    }

    &:hover {
      background-image: linear-gradient(170deg, #fff 5%, #f5f5f5 95%);
      color: $color-primary;
    }

    &:hover::before {
      background-color: $color-secondary;
    }
  }

  .input-group-field {
    min-width: 100px;
  }
}

// @include breakpoint(small only) {
//   form {
//     label {
//       float: none;
//       height: 1rem;
//       line-height: 1rem;
//       width: 100%;
//     }
//
//     input,
//     textarea {
//       width: 100%;
//     }
//   }
// }


label,
.label {
  color: $color-secondary;
  font-size: 1.125rem;
}

fieldset {
  margin-bottom: 35px;
}

legend {
  color: $color-secondary;
  font-family: $font-serif-bold;
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.f3-form-error {
  border: 2px solid red !important;
}

.input-required {
  input,
  select,
  textarea {
    border-color: $color-primary;
  }
}
