.searchresults {
  margin-top: -36px;
}

.searchresult-show {
  text-align: right;
}

.searchresult-show-icon {
  height: 29px;
  margin-right: 10px;
  position: relative;
  top: 8px;
  width: 12px;
}


@include breakpoint(small only) {
  .searchresults tr {
    position: relative;
  }

  .searchresult-edit {
    margin-top: -14px;
    position: absolute;
    right: 10px;
    top: 50%;
  }
}

.sort {
  cursor: pointer;

  &::after {
    content: '';
    display: inline-block;
    height: 0;
    position: relative;
    right: -5px;
    top: 0;
    width: 0;
  }

  &.asc::after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fce6cd;

  }

  &.desc::after {
    border-bottom: 8px solid #fce6cd;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
}


.header-searchform {
  background: url('../Images/section-shadow.png') right bottom no-repeat;
  padding-bottom: 110px;
  padding-top: 0;

  h1 {
    color: $color-primary;
    font-family: $font-serif-regular;
    font-size: 2.625rem;
    font-weight: normal;
    max-width: 100%;
  }

  .header-callout {
    margin-top: 0;
    min-height: 80px;
  }

  .callout-intro h1 {
    color: #fff;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.searchform {
  background: #f6f8fa url('../Images/fs-searchform-bg.jpg') 0 0 no-repeat;
  background-size: cover;
  margin-bottom: -43px;
  padding-bottom: 60px;

  label {
    color: $color-secondary;
    font-size: 1.125rem;
  }

  input,
  select {
    border-color: $color-secondary-light;
  }

  .input-group-label {
    background: $color-secondary-light;
    border-color: $color-secondary-light;
    color: $color-secondary;
    font: 0/0 a;
    padding: 0;

    &::before {
      @include sprite(calendar-dark);
      content: '';
    }
  }

  .location-row {
    font-size: 0;

    span {
      color: $color-secondary;
      font-size: 1.125rem;
      padding: 0 5px;
    }

    label {
      display: inline;
    }

    div {
      display: inline-block;
      width: 75%;
    }

    div + div {
      width: 25%;
    }

    select {
      background-color: $color-secondary-light;
      color: $color-secondary;
    }
  }
}

@include breakpoint(small only) {
  .header-searchform {
    background: none;
  }

  .searchform {
    margin-bottom: 0;
  }
}


.dashboard-header {
  height: 180px;
  position: relative;

  h2 {
    bottom: 0;
    color: $color-primary;
    position: absolute;
  }
}

@include breakpoint(small only) {
  .dashboard-header {
    height: 1.5rem;
    margin-top: 2rem;
  }
}

.dashboard-sidebar {
  h3 {
    border-bottom: 1px solid $color-secondary-light;
    margin-bottom: 10px;
  }

  nav {
    margin-bottom: 30px;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    a {
      display: inline-block;
      line-height: 36px;
      transition: all 200ms linear;
      vertical-align: middle;
    }

    a::before {
      background-color: $color-secondary;
      content: '';
      display: inline-block;
      margin-right: 10px;
      transition: all 200ms linear;
      vertical-align: middle;
    }

    .edit-item::before {
      @include sprite(pencil);
    }

    .add-item::before {
      @include sprite(plus);
    }

    a:link,
    a:visited {
      color: $color-secondary;
    }

    a:hover,
    a:active {
      color: $color-primary;

      &::before {
        background-color: $color-primary;
      }
    }
  }
}


.dashboard-navigation {
  background: url('../Images/section-shadow.png') right bottom no-repeat;
  margin-bottom: 20px;
  margin-top: 60px;
  padding-bottom: 40px;

  @include breakpoint(medium down) {
    margin-top: 0;
  }

  @include breakpoint(small only) {
    margin-top: 20px;
  }
}


.welcome-step {
  position: relative;

  .row + .row {
    padding: 45px 0 25px;
    position: relative;

    @include breakpoint(small only) {
      border-bottom: 1px solid $color-secondary-light;
      padding: 0 0 25px;
    }
  }

  .row + .row::before {
    background: #fff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj48cGF0aCBmaWxsPSIjODI4MjgyIiBkPSJNMTEgMjJMMjIgMEgwIi8+PC9zdmc+') 0 100% no-repeat;
    bottom: 0;
    content: '';
    height: 100%;
    left: 5%;
    position: absolute;
    width: 22px;

    @include breakpoint(small only) {
      display: none;
    }
  }

  .row + .row::after {
    background: $color-neutral-dark;
    bottom: 0;
    content: '';
    height: 110%;
    left: 5%;
    margin-bottom: 6px;
    margin-left: 8px;
    position: absolute;
    width: 6px;

    @include breakpoint(small only) {
      display: none;
    }
  }

  &--last .row + .row::before,
  &--last .row + .row::after {
    display: none;
  }

  h3 span {
    color: $color-primary;
  }

  .cta-btn {
    margin-top: 20px;
  }

  .progress-symbol {
    background: $color-neutral-dark url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4MCIgaGVpZ2h0PSI4MCIgdmlld0JveD0iMCAwIDgwIDgwIj48cGF0aCBmaWxsPSIjRkZGIiBkPSJNNjAuMzQgNTMuMjI3bC03LjExMiA3LjExMy0xMy4yMy0xMy4yM0wyNi43NzMgNjAuMzRsLTcuMTEtNy4xMUwzMi44ODcgNDAgMTkuNjYgMjYuNzczbDcuMTEyLTcuMTEzTDQwIDMyLjg4OCA1My4yMjYgMTkuNjZsNy4xMTIgNy4xMTNMNDcuMTEgNDBsMTMuMjMgMTMuMjI3eiIvPjwvc3ZnPg==') 50% 50% no-repeat;
    border-radius: 50%;
    height: 80px;
    margin: 0 auto;
    opacity: .2;
    width: 80px;
  }

  &--completed {
    h3 {
      color: $color-secondary;
    }

    .progress-symbol {
      background: $color-status-ok url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4MCIgaGVpZ2h0PSI4MCIgdmlld0JveD0iMCAwIDgwIDgwIj48cGF0aCBmaWxsPSIjRkZGIiBkPSJNNjQuMTM3IDI4LjUyMmwtNy40OTgtNy40OThMMzQuMTQgNDMuNTE4IDIyLjg5NSAzMi4yN2wtNy40OTggNy40OTggMTguNzQ1IDE4Ljc0NiIvPjwvc3ZnPg==') 50% 50% no-repeat;
      opacity: 1;
    }

    .cta-btn,
    .cta-btn span {
      opacity: .4;
      pointer-events: none;
    }

    .row + .row::before {
      background: #fff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj48cGF0aCBmaWxsPSIjRUQ3QjAwIiBkPSJNMTEgMjJMMjIgMEgwIi8+PC9zdmc+') 0 100% no-repeat;
    }

    .row + .row::after {
      background: $color-primary;
    }
  }

  &--current {
    h3 {
      color: $color-secondary;
    }
  }

  &--incomplete {
    h3 {
      color: $color-neutral-dark;
    }

    .cta-btn,
    .cta-btn span {
      filter: grayscale(100%);
      opacity: .4;
      pointer-events: none;
    }
  }
}


[data-date-add] {
  background-image: linear-gradient(170deg, #fff 20%, #e9e9e9 100%);
  border: 1px solid #fafafa;
  color: $color-secondary;
  margin-bottom: 10px;
  padding: 6px 8px 3px;

  &:hover {
    color: $color-primary;
  }

  &:disabled {
    opacity: .5;
  }
}

.datepicker-icon {
  height: 22px;
  width: 22px;
}

.course-dates-new {
  margin-bottom: 20px;

  .date {
    margin-bottom: 15px;
  }

  .date-header {
    color: $color-secondary;
    display: block;
    font-size: 1.125rem;
    margin-bottom: 10px;
  }
}

.publicCourse {
  h3,
  h4 {
    color: $color-secondary;
    font-family: $font-serif-bold;
    font-weight: normal;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
  }

  h4 {
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin: 1rem 0 .5rem;
    margin-bottom: .625rem;
  }

  .btn {
    margin: 30px 0;
  }

  .highlight,
  .highlight--dark {
    color: $color-secondary;
    display: block;
    padding: .375rem 0 .375rem .625rem;
  }

  .highlight {
    background: $color-primary-very-light;
  }

  .highlight--dark {
    background: $color-primary-light;
    border-bottom: 2px solid #fff;
    font-family: $font-sans-serif;
    font-weight: 700;
  }

  .color-1,
  .color-2 {
    border-collapse: collapse;
    width: 100%;

    tr {
      border-bottom: 2px solid #fff;
    }

    th,
    td {
      color: $color-secondary;
      display: table-cell;
      font-size: 1rem;
      margin-bottom: 2px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: left;
    }

    a {
      &:link,
      &:visited {
        color: $color-secondary;
      }

      &:hover,
      &:active {
        color: $color-primary;
      }
    }
  }

  .color-1 {
    .th {
      background: $color-primary-light;
    }

    tr {
      background: $color-primary-very-light;
    }
  }

  .color-2 {
    margin-bottom: 25px;

    .th {
      background: $color-secondary-light;
    }

    tr {
      background: $color-secondary-very-light;
    }
  }
}

@include breakpoint(small only) {
  .publicCourse {
    .column-3,
    .column-2 {
      [colspan='2'] {
        width: 100%;
      }
    }

    .column-3 {
      th,
      td {
        display: inline-block;
        width: 30%;
      }
    }

    .column-2 {
      th,
      td {
        display: inline-block;
        width: 45%;
      }
    }
  }
}


.assisted-input {
  position: relative;

  .help-trigger {
    background-color: $color-primary-light;
    border-radius: 50%;
    content: '';
    cursor: pointer;
    font: 0/0 a;
    height: 30px;
    position: absolute;
    right: -45px;
    top: 5px;
    transition: all 200ms linear;
    width: 30px;

    &::after {
      background-image: url('../Images/sprite/info.svg');
      background-size: 20px 20px;
      content: '';
      display: inline-block;
      height: 20px;
      margin-left: 5px;
      margin-top: 5px;
      width: 20px;
    }

    @include breakpoint(small only) {
      display: none;
    }
  }

  .help-text {
    background: $color-secondary-light;
    border: 1px solid $color-secondary;
    color: $color-secondary;
    font-size: 1rem;
    font-style: normal;
    opacity: 0;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 8px;
    transition: visibility 0s 200ms, opacity 200ms linear;
    visibility: hidden;
    width: 100%;
    z-index: 10;

    @include breakpoint(small only) {
      display: none;
    }

    &::after,
    &::before {
      border: solid transparent;
      content: ' ';
      height: 0;
      left: 100%;
      pointer-events: none;
      position: absolute;
      top: 19px;
      width: 0;
    }

    &::after {
      border-color: transparent;
      border-left-color: $color-secondary-light;
      border-width: 12px;
      margin-top: -12px;
    }

    &::before {
      border-color: transparent;
      border-left-color: $color-secondary;
      border-width: 13px;
      margin-top: -13px;
    }
  }

  .input-help:hover {
    .help-trigger {
      background-color: $color-secondary-light;
      transform: scale(1.1);
    }

    .help-text {
      opacity: 1;
      transition: opacity 200ms linear;
      visibility: visible;
    }
  }
}


.action-buttons {
  a {
    display: inline-block;
    height: 32px;
    outline: none;
    width: 32px;

    &:hover {
      background-color: $color-primary-very-light;
    }
  }

  svg {
    height: 32px;
    width: 32px;
  }
}


strong.notice {
  border-radius: 1px;
  display: inline-block;
  font-size: .5rem;
  padding: 2px 4px;
  position: relative;
  text-transform: uppercase;
  top: -3px;

  &.course-not-public {
    background-color: $color-primary;
    color: #fff;
  }
}

p.notice {
  background-color: $color-primary-very-light;
  border: 1px solid $color-primary-light;
  border-radius: 2px;
  color: $color-secondary;
  font-size: 1rem;
  padding: 10px 15px;
}

.container-backlink {
  border-top: 2px solid $color-secondary-very-light;
  margin-top: 20px;
}

.error-container {
  background: $color-primary-very-light;
  border: 3px solid $color-primary;
  margin-bottom: 30px;
  padding: 15px 20px;

  h3 {
    color: $color-primary;
    margin: 0;
  }
}


.icon {
  &::before {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    height: 32px;
    width: 32px;
  }
}

.icon--edit {
  &::before {
    background-image: url('../Images/sprite/pencil-orange.svg');
    background-size: 22px 22px;
  }
}

.icon--show {
  &::before {
    background-image: url('../Images/sprite/eye-orange.svg');
    background-size: 30px 25px;
  }
}

.icon--delete {
  &::before {
    background-image: url('../Images/sprite/trash-orange.svg');
    background-size: 30px 30px;
  }
}

.icon--datepicker {
  &::before {
    background-image: url('../Images/sprite/clock-orange.svg');
    background-size: 24px 24px;
  }
}

.icon--search-result {
  &::before {
    background-image: url('../Images/sprite/search-result-orange.svg');
    background-size: 12px 19px;
  }
}

.ff-fix {
  float: left;
}
