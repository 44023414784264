// fdatepicker variables and their defaults
$fdatepicker-ui-primary: $color-primary;
$fdatepicker-ui-color: #000 !default;
$fdatepicker-ui-bg: #fff !default;
$fdatepicker-ui-disabled: #eee !default;
$fdatepicker-ui-border-color: #000 !default;
$fdatepicker-ui-border-transparency: .2 !default;
$fdatepicker-ui-border: 1px solid rgba($fdatepicker-ui-color, $fdatepicker-ui-border-transparency) !default;
$fdatepicker-ui-shadow: 0 5px 10px rgba($fdatepicker-ui-color, $fdatepicker-ui-border-transparency) !default;
$fdatepicker-ui-before-color: #ccc !default;
$fdatepicker-ui-old-new-color: #999 !default;
$fdatepicker-ui-text-color: #333 !default;
$fdatepicker-inline-width: 220px !default;

@mixin datepicker() {
  background: $fdatepicker-ui-bg;
  border: 0;
  cursor: pointer;
  font-size: 12px;
  height: 20px;
  padding: 4px 8px;
  text-align: center;
  width: 20px;
}

.datepicker {
  direction: ltr;
  display: none;
  font-family: $font-sans-serif;
  margin-top: 1px;
  padding: 4px;
  position: absolute;

  &.dropdown-menu {
    background-clip: padding-box;
    background-color: $fdatepicker-ui-bg;
    border: $fdatepicker-ui-border;
    box-shadow: $fdatepicker-ui-shadow;
    color: $fdatepicker-ui-text-color;
    display: none;
    float: left;
    font-size: 13px;
    left: 0;
    line-height: 18px;
    list-style: none;
    min-width: 160px;
    position: absolute;
    top: 100%;
    z-index: 1000;

    td,
    th {
      padding: 4px 5px;
    }
  }

  table {
    border: 0;
    margin: 0;
    width: auto;
  }

  td {
    background: $fdatepicker-ui-bg;
    border: 0;
    cursor: pointer;
    font-size: .875rem;
    height: 24px;
    padding: 4px 8px;
    text-align: center;
    width: 20px;

    &.active.day,
    &.active.year {
      background: $fdatepicker-ui-primary;
    }

    &.old,
    &.new {
      color: $fdatepicker-ui-old-new-color;
    }

    &.day.disabled {
      color: $fdatepicker-ui-disabled;
    }

    span {
      cursor: pointer;
      display: block;
      float: left;
      height: 54px;
      line-height: 54px;
      margin: 1%;
      width: 23%;
    }

    span.active {
      background: $fdatepicker-ui-primary;
    }
  }

  th {
    background: $fdatepicker-ui-bg;
    border: 0;
    cursor: pointer;
    font-family: $font-sans-serif;
    font-size: .875rem;
    height: 24px;
    padding: 4px 8px;
    text-align: center;
    width: 20px;

    &.active.day,
    &.active.year {
      background: $fdatepicker-ui-primary;
    }

    &.date-switch {
      width: 145px;
    }

    span.active {
      background: $fdatepicker-ui-primary;
    }
  }

  .cw {
    font-size: 10px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
    width: 12px;
  }

  &.days .datepicker-days,
  &.months .datepicker-months,
  &.years .datepicker-years {
    display: block;
  }

  thead tr:first-child th {
    cursor: pointer;

    &.cw {
      background-color: transparent;
      cursor: default;
    }
  }

  tfoot tr:first-child th {
    cursor: pointer;
  }
}

.datepicker-inline {
  width: $fdatepicker-inline-width;
}

.datepicker-rtl {
  direction: rtl;

  table tr td span {
    float: right;
  }
}

.datepicker-dropdown {
  left: 0;
  top: 0;

  &::before {
    border-bottom: 7px solid $fdatepicker-ui-before-color;
    border-bottom-color: $fdatepicker-ui-border;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: '';
    display: inline-block;
    left: 6px;
    position: absolute;
    top: -7px;
  }

  &::after {
    border-bottom: 6px solid $fdatepicker-ui-bg;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: '';
    display: inline-block;
    left: 7px;
    position: absolute;
    top: -6px;
  }
}

.datepicker>div,
.datepicker-dropdown::before,
.datepicker-dropdown::after {
  display: none;
}

.datepicker-close {
  display: none;
  height: 30px;
  padding: 0;
  position: absolute;
  right: 0;
  top: -30px;
  width: 15px;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}
