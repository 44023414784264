footer {
  background-color: #fff;
  color: $color-text-light;
  font-size: .875rem;
  padding: 40px 0;

  p {
    margin-top: 0;
  }

  ul {
    margin: 0 0 20px;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {

    text-decoration: none;

    &:link,
    &:visited {
      color: $color-text-light;
    }

    &:hover,
    &:active {
      color: $color-secondary;
    }
  }
}

@include breakpoint(small only) {
  footer {
    padding: 20px 0 10px;

    .column {
      border-bottom: 1px solid rgba(#828282, .4);
      padding-bottom: 10px;
      padding-top: 10px;

      &:last-child {
        border-bottom: 0;
      }

      .column {
        border: 0;
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }
}
