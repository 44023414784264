thead,
tfoot {
  th,
  td {
    font-family: $font-serif-regular;
    font-size: 1rem;
  }
}

tbody {
  td {
    color: $color-secondary;
    font-size: 1.125rem;
    vertical-align: top;
  }

  tr {
    border-bottom: 1px solid $color-secondary-light;

    &:last-child {
      border: 0;
    }
  }
}

.searchresult {
  td {
    padding: 18px 0 2px;
  }
}

@include breakpoint(small only) {
  tbody {
    tr {
      display: table-row;
    }
  }

  table tbody td,
  table tbody th {
    display: table-cell;
    padding: 0;
  }

  table tbody tr {
    padding: 10px 0;
  }
}

@include breakpoint(small only) {
  tbody {
    td:first-child {
      padding-right: 2px;
    }
  }
}
