html {
  background: #e8edf3;
  height: 100%;
}

body {
  background: #fff;
  box-shadow: 0 0 30px rgba(#000, .1);
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
}


.section {
  background: url('../Images/section-shadow.png') right bottom no-repeat;
  padding-bottom: 50px;
  padding-top: 20px;
  position: relative;

  &--padding {
    padding-top: 80px;

    @include breakpoint(small only) {
      padding-bottom: 0 !important;
      padding-top: 0;
    }
  }

  &--footernav {
    background: #d8e1ea;
    padding: 40px 0 50px;
  }

  &--quoteslider {
    overflow: hidden;
    padding: 0;
  }

  &--quote {
    background: none;
    padding-bottom: 25px;
    padding-top: 25px;
  }

  &--content-simple {
    background: none;
  }

  &--highlight {
    background: rgba($color-primary, .25) url('../Images/section-highlight-symbol.png') 8% 0 no-repeat;
    margin-bottom: 25px;
    padding: 55px 0 45px !important;

    @include breakpoint(small only) {
      margin-bottom: 0;
    }

    p,
    cite {
      color: $color-primary;
      font-family: $font-serif-regular;
      margin: 0 auto;
      max-width: 70%;
      text-align: center;

      @include breakpoint(small only) {
        max-width: 85%;
      }
    }

    p {
      font-size: 32px;
      line-height: 42px;
    }

    cite {
      display: block;
      font-size: 18px;
      margin-top: 15px;
      text-align: right;
    }
  }
}

header + .section,
header + .section--content,
header + .section--quote,
header + .section--quote-simple {
  padding-top: 0;
}

@include breakpoint(small only) {
  .section {
    padding-bottom: 25px;

    &--quoteslider {
      padding-bottom: 0;
    }

    &--highlight {
      background-size: 17%;
      padding-top: 34px;
    }

    &--highlight p {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    &--highlight cite {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
}



.separated {
  position: relative;

  &::after {
    border-left: 1px solid $color-secondary;
    content: '';
    height: 100%;
    position: absolute;
    right: -12.66667%;
    top: 0;
    width: 1px;
  }
}

@include breakpoint(small only) {
  .separated::after {
    display: none;
  }
}
