%btn {
  background-image: linear-gradient(170deg, #fff 20%, #e9e9e9 100%);
  border: 0;
  color: $color-secondary;
  display: inline-block;
  font-size: 1.125rem;
  margin-bottom: 10px;
  min-height: 36px;
  padding: 6px 8px 3px 45px;
  position: relative;
  text-decoration: none;
  transition: all 100ms linear;

  &:link,
  &:visited {
    color: $color-secondary;
  }

  &::before {
    background-color: $color-primary;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 100ms linear;
  }

  &:hover {
    background-image: linear-gradient(170deg, #fff 5%, #f5f5f5 95%);
    color: $color-primary;
  }

  &:hover::before {
    background-color: $color-secondary;
  }

  &.fancy {
    box-shadow: 0 1px 3px rgba(#000, .3);
  }

  &.block {
    display: block;
  }
}

.btn--nomargin {
  margin: 3px 0 !important;
  width: 100%;
}

.btn {
  @extend %btn;
}

.cta-btn {
  @extend %btn;
  @extend %btn.fancy;
  padding-left: 50px;
  padding-right: 12px;

  &::before {
    @include sprite(plus);
  }

  &::after {
    background-position: 50% 0;
    background-repeat: no-repeat;
    content: '';
    height: 44px;
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(10px, -44px);
    transition: transform 200ms linear;
    width: 66px;
  }

  &:hover::after {
    transform: translate(15px, -44px);
  }

  &--course::after {
    background-image: url('../Images/fs-button-course.svg');
  }

  &--location::after {
    background-image: url('../Images/fs-button-location.svg');
  }

  &--contact::after {
    background-image: url('../Images/fs-button-person.svg');
  }
}

@include breakpoint(medium down) {
  .cta-btn {
    &--course::after,
    &--location::after,
    &--contact::after {
      background-image: none;
    }
  }
}

.socialshare-icon {
  display: inline-block;
  height: 40px;
  margin-right: 5px;
  width: 40px;
}

