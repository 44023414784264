.tx-hairu {
  margin-top: 20px;

  label {
    line-height: 1.5rem;
    padding-top: .6rem;
  }

  .form-group {
    margin: 0;
  }

  .btn {
    margin: 12px 0 18px;
  }

  input {
    margin-bottom: 18px;
  }

  p {
    margin: 0;
  }

  .alert-message {
    color: $color-secondary;
    margin-bottom: 50px;

    b {
      color: $color-primary;
      display: inline-block;
      font-size: 1.5rem;
      margin-bottom: .5rem;
    }
  }

  .alert-danger {
    color: $color-secondary;
    margin-bottom: 50px;

    p {
      color: $color-primary;
      display: inline-block;
      font-size: 1.5rem;
      margin-bottom: .125rem;
    }
  }

}
