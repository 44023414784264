.femanager_new {

  legend {
    color: $color-secondary;
    font-family: $font-serif-bold;
    font-size: 1.125rem;
    margin-bottom: 35px;
  }

  .control-label {
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding-top: .6rem;
  }

  .alert {
    background: $color-primary;
    padding: 3px 0 3px 8px;

    span {
      color: #fff;
      display: inline-block;
      font-family: $font-sans-serif;
      font-size: .875rem;
    }
  }

  .btn {
    margin-top: 20px;
  }
}

.tx-femanager {
  .container-info {
    background: $color-secondary-very-light;
    margin-bottom: 40px;
    padding: 12px 15px;

    h3 {
      font-size: 1.125rem;
      margin: 0;
    }
  }

  .highlight {
    color: $color-secondary;
  }

  ul {
    margin-top: 10px;
  }
}
