$color-primary: #ed7b00;
$color-secondary: #003a75;

$color-text-light: #828282;

$color-primary-light: #fbdec0;
$color-primary-very-light: #fdefe0;

$color-secondary-light: #d8e1ea;
$color-secondary-very-light: #f3f3f3;

$color-neutral-dark: $color-text-light;

$color-status-ok: #5aa95c;

$font-serif-bold: 'WeidemannStd-Bold';
$font-serif-bold-italic: 'WeidemannStd-BoldItalic';
$font-serif-regular: 'WeidemannStd-Book';
$font-serif-regular-italic: 'WeidemannStd-BookItalic';
$font-sans-serif: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
$font-sans-serif-bold: 'HelveticaNeueBold', 'HelveticaNeue-Bold', 'Helvetica Neue Bold', 'HelveticaNeue', 'Helvetica Neue', 'TeXGyreHerosBold', 'Helvetica', 'Tahoma', 'Geneva', 'Arial', sans-serif;

$transition-default: all 75ms ease-in;
