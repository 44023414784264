.inline-quote {
  font-size: 1.125rem;
  margin: 0;

  cite {
    color: #828282;
    font-style: normal;
  }

  p {
    color: $color-primary;
    font-family: $font-serif-regular;
    margin: 0;
  }
}

@include breakpoint(medium down) {
  .inline-quote {
    p {
      padding-bottom: 20px;
    }
  }
}

@include breakpoint(medium only) {
  .inline-quote {
    background: url('../Images/section-quote-symbol.png') 8% 0 no-repeat;
  }
}

.teaserbox {
  display: block;
  margin-bottom: 15px;
  overflow: hidden;
  perspective: 600px;
  position: relative;
  text-decoration: none;

  &__imageeffect {
    box-shadow: inset 2px 2px 14px 0 rgba(#000, .8);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::after {
    background-image: linear-gradient(-90deg, rgba(#000, .3) 0%, transparent 60%);
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 150ms ease-out 10ms;
    width: 100%;
    z-index: 1;
  }

  &__text {
    background-color: $color-secondary;
    color: #fff;
    font-family: $font-serif-regular;
    font-size: 1.5rem;
    height: 100%;
    left: 0;
    padding: 15px;
    position: absolute;
    top: 0;
    transition: all 150ms ease-in;
    width: 100%;

    span {
      display: block;
      max-width: 80%;
    }

    &::after {
      background: url('../Images/teaserbox-triangle.png') 0 0 no-repeat;
      bottom: 20px;
      content: '';
      height: 50px;
      position: absolute;
      right: 25px;
      width: 41px;
    }
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    .teaserbox__text {
      opacity: .9;
      transform: translateX(10%) rotateY(-30deg);
    }
  }
}

@include breakpoint(small only) {
  .teaserbox {
    &__text {
      min-height: 100px;
      position: relative;
    }

    &__text::after {
      display: none;
    }

    img {
      display: none;
    }

    &:hover {
      .teaserbox__text {
        opacity: 1;
        transform: none;
      }
    }
  }
}

@include breakpoint(medium down) {
  .teaserbox {
    &__text {
      font-size: 1.25rem;
    }
  }
}


.seminars {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 100%;

  @include breakpoint(small only) {
    margin-top: 1rem;
  }

  &::before,
  &::after {
    content: '';
    order: 1;
    width: 100%;
  }
}

.seminar {
  align-items: center;
  background: $color-primary;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 4px;
  padding: 5px;
  position: relative;
  text-decoration: none;
  transition: all 100ms linear;

  &:hover {
    background-color: $color-secondary;
    box-shadow: inset 2px 2px 8px 0 rgba(#000, .8);
  }

  span {
    color: #fff;
    flex: 1;
    text-align: center;
  }

  &::before {
    content: '';
    float: left;
    padding-top: 100%;
  }

  ~ .seminar {
    margin-left: 4px;
  }
}

@include breakpoint(small only) {
  .seminar {
    font-size: 1rem;

    &:nth-child(n+5) {
      order: 1;
    }

    &:nth-child(n+9) {
      order: 2;
    }

    &:nth-child(5),
    &:nth-child(9) {
      margin-left: 0;
    }
  }
}

@include breakpoint(medium only) {
  .seminar {
    font-size: 1.1875rem;

    &:nth-child(n+7) {
      order: 1;
    }

    &:nth-child(7) {
      margin-left: 0;
    }
  }
}



.quoteslider {
  padding: 45px 0 0;
  position: relative;

  blockquote {
    display: inline-block;
    margin: 0;
    padding-bottom: 30px;
    position: relative;

    &::after {
      background: url('../Images/quoteslider-quote-arrow.png') 0 0 no-repeat;
      content: '';
      height: 81px;
      position: absolute;
      right: -55px;
      top: 28%;
      width: 93px;
    }

    p {
      color: $color-primary;
      font-family: $font-serif-regular;
      font-size: 1.125rem;
      margin-bottom: 15px;
      padding: 10px 25px 15px 0;
      position: relative;
    }

    p::after {
      background: url('../Images/quoteslider-quote-bottom.png') 0 0 no-repeat;
      bottom: 0;
      content: '';
      height: 0;
      position: absolute;
      right: 0;
      width: 8px;
    }
  }

  .quote-image {
    display: table;
  }

  .quote-imagewrapper {
    display: table-cell;
    vertical-align: bottom;
  }

  cite {
    color: $color-secondary;
    font-size: 1.125rem;
    font-style: normal;
  }

  .quoteslider-navigation {
    position: relative;

    .btn {
      display: block;
      margin: 0 auto;
      width: 70%;
    }
  }

  .slick-slide {
    outline: none;

    img {
      display: inline-block;
    }
  }

  .quoteslider-navigation-prev,
  .quoteslider-navigation-next {
    cursor: pointer;
    fill: $color-primary;
    height: 96px;
    margin: -30px 0 0 -10px;
    opacity: .2;
    padding: 30px 10px;
    position: absolute;
    top: 50%;
    transition: all 200ms linear;
    width: 49px;

    &:hover {
      fill: $color-secondary;
      opacity: 1;
    }
  }

  .quoteslider-navigation-prev {
    left: 20px;
  }

  .quoteslider-navigation-next {
    right: 20px;
  }
}

@include breakpoint(small only) {
  .quoteslider {
    background: url('../Images/section-shadow.png') right bottom no-repeat;
    padding-top: 0;

    .medium-4 {
      text-align: right;
    }

    img {
      max-width: 300px;
      width: 66.6666%;
    }

    .slick-prev {
      left: 0;
      top: 40%;
    }

    .slick-next {
      right: 0;
      top: 40%;
    }

    .btn {
      display: block;
      margin: 10px auto 40px;
      width: 80%;
    }

    blockquote {
      margin: 0;
      padding: 0 0 15px;

      p {
        background: none;
        padding: 0;
      }

      p::after,
      &::after {
        display: none;
      }
    }
  }
}
