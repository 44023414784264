.sticky {
  background: transparent;
  margin: 0 auto;
  max-width: 100%;
  position: fixed;
  top: 0;
  width: 1280px;
  z-index: 999;

  @include breakpoint(small only) {
    position: static;
  }
}

.topnav-wrapper.sticky {
  .topnav {
    background: #a4b8cb;
  }
}

.topnav,
.footernav {
  padding: 40px 0;

  strong {
    color: $color-secondary;
    display: block;
    font-family: $font-serif-bold;
    font-size: 1.125rem;
    font-weight: normal;
    margin-bottom: 15px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    margin-bottom: 10px;
  }

  a {
    &:link,
    &:visited {
      color: $color-secondary;
      text-decoration: none;
      transition: $transition-default;
    }

    &:hover,
    &:active {
      color: darken($color-secondary, 15%);
    }
  }
}

.topnav {
  @include breakpoint(small only) {
    padding: 10px 0;
  }


  li {
    padding-left: 13px;
    position: relative;

    &::before {
      background: $color-secondary;
      content: '';
      height: 5px;
      left: 0;
      position: absolute;
      top: 9px;
      width: 5px;
    }

    &:hover {
      &::before {
        background: $color-primary;
      }
    }

    &.active,
    &.current {

      &::before {
        background: $color-primary;
      }

      a {
        color: darken($color-secondary, 15%);
      }

      .subsubmenu {
        display: block;
      }
    }
  }

  a {
    &:link,
    &:visited {
      line-height: 1rem;
    }
  }

  .level-3 {
    margin-top: .5rem;
    padding-left: .25rem;

    li {
      margin-bottom: 3px;
    }
  }

  .subsubmenu {
    display: none;
    margin-top: 8px;
    padding-left: 0;

    li {
      margin-bottom: 2px;

      a {
        color: $color-secondary;
      }
    }

    .active,
    .current {
      a {
        color: darken($color-secondary, 15%);
      }
    }

    .active,
    .current {
      .subsubsubmenu {
        display: block;
      }
    }
  }

  .subsubsubmenu {
    display: none;
    margin-top: 8px;
    padding-left: 0;

    li {
      margin-bottom: 0;

      a {
        color: $color-secondary !important;
      }
    }

    .active,
    .current {
      a {
        color: darken($color-secondary, 15%) !important;
      }
    }
  }
}

.footernav {
  nav {
    font-size: .875rem;
  }

  ul ul {
    display: none;
  }
}

@include breakpoint(small only) {
  .footernav {
    padding-bottom: 20px;

    strong {
      margin-bottom: 3px;
    }

    nav {
      margin-bottom: 20px;
    }
  }
}


@keyframes animate-cloud {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-20%);
  }
}

.topnav {
  background-color: rgba(#00386a, .2);
  background-image: linear-gradient(to bottom, rgba(#00386a, .2) 0%, rgba(#2a6298, .2) 100%);
  overflow: hidden;
  position: relative;

  .row {
    position: relative;
    z-index: 10;
  }

  &::after {
    border-radius: 50%;
    bottom: -5%;
    box-shadow: 0 0 30px rgba(#000, .3);
    content: '';
    height: 5%;
    left: 5%;
    position: absolute;
    width: 90%;
  }

  .layer-1,
  .layer-2 {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translate3d(0, 0, 0);
    width: 1000%;
  }

  .layer-1 {
    animation: animate-cloud 200s linear infinite;
    background: url('../Images/animated-1.png') left bottom repeat-x;
  }

  .layer-2 {
    animation: animate-cloud 200s linear infinite;
    background: url('../Images/animated-2.png') left bottom repeat-x;
  }
}


.topnav-container {
  background: #fff;
  max-height: 0;
  overflow: hidden;
  transition: all 500ms linear;

  &.open {
    max-height: 900px;
    overflow-y: hidden;
  }
}


.headernav {
  a {
    background-image: linear-gradient(170deg, #fff 20%, #e9e9e9 100%);
    color: $color-secondary;
    display: inline-block;
    font-size: 1.125rem;
    height: 36px;
    padding: 6px 8px 3px 45px;
    position: relative;
    text-decoration: none;
    transition: all 100ms linear;

    &::before {
      background-color: $color-primary;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all 100ms linear;
      width: 36px;
    }

    &:hover {
      background-image: linear-gradient(170deg, #fff 5%, #f5f5f5 95%);
      color: $color-primary;
    }

    &:hover::before {
      background-color: $color-secondary;
    }

    &.icon--search::before {
      @include sprite('magnifier');
    }

    &.icon--profile {
      &::before {
        @include sprite('person');
        background-color: $color-secondary;
      }
    }
  }
}

.start .headernav .icon {
  display: none;
}


.hamburger-icon {
  cursor: pointer;
  height: 18px;
  left: 6px;
  position: absolute;
  top: 8px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  width: 24px;

  span {
    background: #fff;
    display: block;
    height: 4px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transform-origin: left center;
    transition: .25s ease-in-out;
    width: 100%;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2) {
    top: 7px;
  }

  span:nth-child(3) {
    top: 14px;
  }

  &.open span:nth-child(1) {
    left: 4px;
    top: 0;
    transform: rotate(45deg);
  }

  &.open span:nth-child(2) {
    opacity: 0;
    width: 0%;
  }

  &.open span:nth-child(3) {
    left: 4px;
    top: 17px;
    transform: rotate(-45deg);
  }
}

// Pagination

.pagination-container {
  margin-top: 1rem;
  text-align: center;
}

.pagination {
  display: inline-block;
  margin-left: -.3125rem;
  min-height: 1.5rem;
  padding-left: 0;

  li {
    color: #003a75;
    display: block;
    float: left;
    font-size: .875rem;
    height: 1.5rem;
    margin-left: .3125rem;

    a,
    button {
      background: 0 0;
      background-color: rgba($color-primary, .4);
      border-radius: 3px;
      color: #000;
      display: block;
      font-size: 1em;
      font-weight: 400;
      line-height: inherit;
      padding: .0625rem .625rem;
      transition: background-color 300ms ease-out;
    }

    &.active {
      a,
      button {
        background: $color-primary;
        color: #fff;
      }
    }

    a:focus,
    button:focus {
      background: #e6e6e6;
    }

    &:hover {
      a,
      button {
        background: $color-primary;
        color: #fff;
      }
    }

    &.unavailable {
      a,
      button {
        color: #999;
        cursor: default;
      }

      a:focus,
      button:focus {
        background: 0 0;
      }

      &:hover {
        a,
        button {
          background: 0 0;
        }
      }
    }

    &.current {
      a,
      button {
        background: #dadada;
        color: #fff;
        cursor: default;
        font-weight: 700;
      }

      a {
        &:focus,
        &:hover {
          background: #dadada;
        }
      }

      button {
        &:focus,
        &:hover {
          background: #dadada;
        }
      }
    }
  }
}
