h2 {
  color: $color-secondary;
  font-family: $font-serif-bold;
  font-size: 1.5rem;
  font-weight: normal;
}

h3 {
  color: $color-secondary;
  font-family: $font-serif-bold;
  font-size: 1.5rem;
  font-weight: normal;
}

h4 {
  color: #000;
  font-family: $font-sans-serif-bold;
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.125rem;
  margin: 1rem 0 .125rem;
}

b,
strong {
  font-family: $font-sans-serif-bold;
  font-stretch: normal;
  font-weight: 600;
}

@include breakpoint(small only) {
  h2 {
    line-height: 1.75rem;
    margin-bottom: 0;
  }
}

a {
  text-decoration: none;

  &:link,
  &:visited {
    color: $color-primary;
  }

  &:hover,
  &:active {
    color: $color-secondary;
  }
}


.bodytext,
.ce-bodytext p,
.list li {
  color: $color-secondary;
  font-size: 1.125rem;
  line-height: 1.4375rem;
}

.ce-uploads-list,
.list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 10px;
    padding-left: 24px;
    position: relative;

    &::before {
      background: $color-primary;
      content: '';
      height: 12px;
      left: 0;
      position: absolute;
      top: 6px;
      width: 12px;
    }
  }
}


.ce-uploads {
  background: url('../Images/uploads-symbol.png') top left no-repeat;
}
